<template>
  <v-container fluid class="grey lighten-3">

    <v-card>
      <v-card-title>
        {{ pageTitle }}
      </v-card-title>

      <v-card-text>
        <v-container fluid>

          <v-row>
            <v-col md="6">
              <v-form ref="form">
                <v-text-field v-model="item.keyword" :disabled="!hasSEAPermissions" label="Keyword" hint="Keyword without match type modifiers such as quotes or brackets" required :rules="[v => !!v || 'Keyword is required']"></v-text-field>

                <v-autocomplete v-model="item.match_type" :disabled="!hasSEAPermissions" :items="matchTypes" label="Match type"></v-autocomplete>
                <v-autocomplete v-model="item.keyword_type" :disabled="!hasSEAPermissions" :items="keywordTypes" item-text="name" item-value="name" label="Keyword type">
                  <template v-slot:item="data">
                    <v-list-item-icon>
                      <v-icon>{{data.item.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>

                <v-row align="center" class="mt-2 mb-5">
                  <span class="ml-3 mt-0 mr-3 settings-label">Sync to ad networks</span>
                  <div v-for="adNetwork in adNetworks" :key="adNetwork">
                    <v-checkbox v-model="selectedAdNetworks" :disabled="!hasSEAPermissions" :label="adNetwork" :value="adNetwork" dense hide-details class="shrink mt-0 mr-2"></v-checkbox>
                  </div>
                </v-row>

              </v-form>

              <v-card-actions class="pb-5">
                <v-btn class="mr-2" :to="{ name: 'Negative keywords' }">Back</v-btn>
                <v-btn class="mr-4" color="primary" :disabled="!hasSEAPermissions" :loading="saving" @click="save()">Save</v-btn>
                <v-btn color="error" :disabled="!deleteEnabled" :loading="deleting" @click="softDelete()">Delete</v-btn>

                <v-fade-transition mode="out-in">
                  <v-btn color="green darken-1" text v-show="showSaved">Saved</v-btn>
                </v-fade-transition>

              </v-card-actions>
            </v-col>

            <v-col md="6">
              <NKWComments :nkwID="id"></NKWComments>
            </v-col>

          </v-row>

          <v-row>
            <v-col md="6">
              <NKWRegions :nkwID="id" :nkwType="item.keyword_type" @changed="syncAccountsTableKey = $dateTime.now().toString()"></NKWRegions>
            </v-col>
            <v-col md="6">
              <NKWCountries :nkwID="id" :nkwType="item.keyword_type" @changed="syncAccountsTableKey = $dateTime.now().toString()"></NKWCountries>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="6">
               <NKWSyncAccountsTable v-if="hasSEAPermissions" :nkwID="id" :updateKey="syncAccountsTableKey"></NKWSyncAccountsTable>
            </v-col>

            <v-col md="6">

              <v-container fluid class="pb-8">
                <v-row>
                  <v-col>
                    <h3 class="mb-2">Audit (keyword only)
                      <v-btn v-show="!showAudit" icon @click="showAudit = true">
                        <v-icon small>visibility</v-icon>
                      </v-btn>
                      <v-btn v-show="showAudit" icon @click="showAudit = false">
                        <v-icon small>visibility_off</v-icon>
                      </v-btn>
                    </h3>
                    <v-simple-table v-show="showAudit">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>Action</th>
                            <th>User</th>
                            <th>Timestamp</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Entry</td>
                            <td>{{ item.entry_by }}</td>
                            <td>{{ datatables_formatTimestamp(item.entry_at) }}</td>
                          </tr>
                          <tr>
                            <td>Last modified</td>
                            <td>{{ item.last_modified_by }}</td>
                            <td>{{ datatables_formatTimestamp(item.last_modified_at) }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-container>

            </v-col>
          </v-row>

        </v-container>
      </v-card-text>

    </v-card>

  </v-container>
</template>

<script>
import auth from '@/auth'
import formsMixin from '@/mixins/forms'
import dataTablesMixin from '@/mixins/datatables'

export default {
  name: 'NegativeKeywordDetail',

  props: {
    id: { type: Number, required: true },
  },

  data () {
    return {
      item: {
        notated_keyword: null,
        keyword: null,
        match_type: null,
        keyword_type: '', // passed as param, avoid NULL state
        ad_networks_csv: null,
        has_protected_booking: null,
        entry_by: null,
        entry_at: null,
        last_modified_by: null,
        last_modified_at: null,
      },
      selectedAdNetworks: [],
      syncAccountsTableKey: 'init',
      valid: false,
      loading: false,
      saving: false,
      deleting: false,
      showSaved: false,
      showAudit: false,
    }
  },

  mixins: [dataTablesMixin, formsMixin],

  computed: {
    adNetworks () {
      return this.$store.state.core.adNetworks
    },
    deleteEnabled () {
      if (this.item.has_protected_booking) {
        if (auth.hasPermission('SEA Senior') ||  auth.hasPermission('Tech')) {
          return true
        }
      } else {
        if (auth.hasPermission('SEA Basic') || auth.hasPermission('SEA Senior') ||  auth.hasPermission('Tech')) {
          return true
        }
      }
      return false
    },
    hasSEAPermissions () {
      if (auth.hasPermission('SEA Basic') || auth.hasPermission('Tech')) {
        return true
      }
      return false
    },
    itemEndpoint () {
      return '/a/nkw/keywords/' + this.id
    },
    keywordTypes () {
      return this.$store.state.nkw.keywordTypes
    },
    matchTypes () {
      return this.$store.state.core.matchTypes
    },
    pageTitle () {
      return this.item.notated_keyword ? this.item.notated_keyword : 'Keyword'
    }
  },

  components: {
    NKWComments: () => import('@/components/negativeKeywords/NKWComments'),
    NKWCountries: () => import('@/components/negativeKeywords/NKWCountries'),
    NKWRegions: () => import('@/components/negativeKeywords/NKWRegions'),
    NKWSyncAccountsTable: () => import('@/components/tables/NKWSyncAccountsTable'),
  },

  methods: {
    fetchItem: function () {
      this.loading = true

      // reset data (to bring back deleted item fields)
      Object.assign(this.$data, this.$options.data.apply(this))

      var myURL = this.itemEndpoint + '?xfields='
      // only get the fields needed to fill the item object
      myURL = this.forms_addItemFieldsToURI(myURL, this.item, [])

      this.$http.get(myURL).then(resp => {
        this.item = resp.data.data
        this.selectedAdNetworks = this.item.ad_networks_csv.split(', ')
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    save: function () {
      // make sure all mandatory fields have a value
      if (!this.$refs.form.validate()) {
        return
      }
      this.saving = true

      // only send the required item fields to API
      delete this.item['id']
      delete this.item['notated_keyword']
      delete this.item['entry_by']
      delete this.item['entry_at']
      delete this.item['last_modified_by']
      delete this.item['last_modified_at']

      // trim kw
      this.item['keyword'] = this.item['keyword'].trim()

      this.item.ad_networks_csv = this.selectedAdNetworks.join(',')

      var body = JSON.stringify(this.item)
      this.$http.put(this.itemEndpoint, body).then(resp => {
        this.showSaved = true
        this.timer = setTimeout(() => { this.showSaved = false }, 3000)
        this.fetchItem()
        this.syncAccountsTableKey = this.$dateTime.now().toString()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'PUT', url: this.itemEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.saving = false)
    },

    softDelete: function () {
      if (!confirm('Are you sure?')) {
        return
      }
      
      this.deleting = true
      var myURL = this.itemEndpoint + '/soft'

      this.$http.delete(myURL).then(resp => {
        this.$router.push('/negative-keywords')
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'DELETE', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.deleting = false)
    },
  },

  created: function () {
    this.fetchItem()
  },
}
</script>

<style scoped>

.settings-label {
  color: rgba(0, 0, 0, 0.6);
}

.shrink >>> label {
  font-size: 14px;
  width: 50px;
}
</style>